<template>
  <div class="content_tt">
    <div class="header_uh">Aguarlu....</div>
    <div class="content_tc">
      <div class="oderT">Please check your order:</div>
      <div class="oder_text">
        Hello, you have successfully paid for {{}} products and spent a total of $3290
        If you want to continue to spend, please return to the home page to buy again
      </div>
      <div class="inputDi">
        <input placeholder="Please upload your order number" v-model="inputOrderNumber"/>
      </div>
      <button @click="getProduce()">Enviar</button>
      <!--		    <uni-popup ref="popups" mode="center" border-radius="20" height="300px" width="400px" :closeable="true">-->
      <!--			    <div class="payt">Order number entered successfully</div>-->
      <!--		    </uni-popup>-->
    </div>
  </div>
</template>

<script>
import {saveRolllnfo} from '@/api/api'

export default {
  name: 'TC2023',
  data() {
    return {
      saveType: 1,
      inputOrderNumber: '',
      payOrderId:null,
    }
  },
  mounted() {
    this.payOrderId = this.$route.params.id
  },
  methods: {
    getProduce() {
      if (this.inputOrderNumber == '') {
        this.$message.error('Please enter your correct order number');
      } else {
        saveRolllnfo(this.payOrderId, this.saveType, this.rollOutAccount, this.rollOutName,this.inputOrderNumber).then(res => {
          this.$message({
            message: res,
            type: 'success',
            duration: '1000',
            onClose:  () =>{
              this.$router.push({path: '/qrcodeOrderStatePage/' + this.payOrderId})
            }
          });
          // this.$router.push("../qrcode/TC2022?payOrderId"+this.payOrderId)
        }).catch(err => {
          console.log(err)
        })
      }
    },
  }
}
</script>

<style scoped>
.content_tt {
  background-color: rgba(200, 181, 245, 0.2);
  height: 100vh;
  text-align: left;
  /* padding:30rpx 20rpx; */
}

.header_uh {
  background-color: #724ec6;
  color: #fff;
  text-align: center;
  height: 90px;
  line-height: 90px;
  font-size: 40px;
}

.content_tc {
  padding: 30px 20px;
}

.oderT {
  font-size: 35px;
  margin-bottom: 50px;
}

.inputDi {
  width: 100%;
  padding: 10px 0;
  border: 1px solid rgba(123, 24, 169, 0.8);
  border-radius: 10px;
  margin: 20px 0px 30px;
  display: flex;
}

.inputDi input {
  width: 100%;
  font-size: 30px;
  background: none;
  outline: none;
  border: none;
  padding: 10px;
}

.oder_text {
  font-size: 30px;
  color: #da0e73;
  margin: 10px 0px 40px;
}

button {
  width: 100%;
  font-size: 40px;
  background-color: #724ec6;
  color: #fff;
  border: none;
  padding: 17px 0;
}

.payt {
  padding: 120px 30px;
  font-size: 30px;
  color: #724ec6;
  font-weight: 700;
}
</style>
